import * as React from "react";
import { graphql } from "gatsby";
import { Link, withPrefix } from 'gatsby';
import {
  Layout, SEO, CBreadCrumb, LWrap, CBtnList,
} from "../../../components/_index"
import { size } from "lodash";
import { setLang } from "../../../utils/set-lang"

// markup
const SubPage = ({ data }: { data: any }) => {
  const frontmatter = data?.markdownRemark?.frontmatter
  return (
    <Layout isKv={false}>
      <SEO title={frontmatter?.title}
        description={frontmatter?.description}
      />
      <CBreadCrumb data={{
        parent: [
          {
            label: "ご利用規約",
            path: "/agreement/"
          }
        ],
        current: {
          label: frontmatter?.title.includes('-') ? frontmatter?.title.split('-')[0] : frontmatter?.title
        }
      }} />
      <section className="l_sect02">
        <LWrap>
          <h1 className="c_headingLv2">サイト利用規約</h1>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">1. ご利用にあたって</h2>
            <p>三菱地所ホテルズ＆リゾーツ株式会社（以下「当社」）のウェブサイト「 <Link to="https://www.royalparkhotels.co.jp/" target="_blank" rel="noopner noreferrer">https://www.royalparkhotels.co.jp/</Link> 以下のディレクトリー、および各種予約プログラムとメール送信プログラム」（以下「本サイト」）は、当社又はその代理人が運営しております。当社ではお客様のご利用に際し、サイト利用規約を設けさせていただいております。本サイトのご利用の前に、以下のサイト利用規約をお読み下さい。本サイトをご利用いただきました場合、以下の利用規約に同意されたものとみなします。 本サイト利用規約は予告なく変更することがありますので、必ず最新情報をご確認下さい。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">2. サービス内容の変更について</h2>
            <p>当社では本サイト上で提供するサービスの内容を予告なく変更することがあります。また、保守作業やシステム障害その他の理由により、予告なく一時的または長期的に本サイトの全部または一部の運営を中止することがあります。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">3. 著作権</h2>
            <p>本サイトに掲載する情報の著作権は、原則として当社に帰属します。当社の文章による承諾がある場合を除き、本サイトに掲載された情報の二次使用を禁止します。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">4. 免責事項</h2>
            <p>当社では本サイトに掲載する情報について細心の注意を払っております。しかし当社はその完全性を保障するものではありません。当社の営業に関する情報の詳細は、全て現況を優先いたします。本サイトの利用により、利用者がいかなる損害を受けた場合にも、当社は一切の責任を負いません。また、本サイトの保守作業やシステム障害その他の理由によりサービスが中断し、その結果利用者が損害を被った場合においても、当社は一切の責任をおいません。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">5. 本サイトへのリンク</h2>
            <p>本サイトへのリンクは原則的に自由です。ただし、各ファイルのURLは予告なしに変更されることがありますので、必ずトップページ<Link to="https://www.royalparkhotels.co.jp/" target="_blank" rel="noopner noreferrer">https://www.royalparkhotels.co.jp/</Link>へリンクしてください。画像のみのリンクやフレーム内への利用はご遠慮ください。また、リンクご希望の場合は、予めその旨を当社までご通知ください。</p>
          </section>
          <section className="u_mbMedium">
            <h2 className="c_headingLv3">6. リンク先に対する当社の免責</h2>
            <p>本サイトは、本サイト内で提供する情報を補完するため、グループホテル、提携施設そしてその他の第三者のウェブサイトへのリンクを張ることがあります。本サイトからリンクを張っているグループホテル以外の第三者のウェブサイトの内容はそれぞれの責任で管理されるものです。当社はグループホテル以外のリンク先サイトの内容について、あるいはそれらをご利用になったことにより生じた損害について、一切の責任を負いません。</p>
          </section>
        </LWrap>
      </section>
    </Layout>
  )
}

export default SubPage;

export const query = graphql`
  query ($slug: String!){
    markdownRemark(fields: {slug: {eq: $slug}}) {
      html
      frontmatter {
        title
        description
      }
    }
  }
`